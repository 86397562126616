import React, { useEffect } from 'react';

import { Button, Fab, Input, Modal, VStack } from 'native-base';
import MaterialIconByName from '../MaterialIconByName';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { IManualJobEntryInput, ScannerId } from 'src/models';
import { isAppOrWindows } from 'src/utils/utils';
import { inputStyle } from 'src/styles/inputStyle';
import { getText } from 'src/utils/i18n';
interface FabButtonProps {
    isDisabled?: boolean;
    captureImageClicked: () => void;
    imageUploadClicked: () => void;
    scanImageClicked: () => void;
    doubleSidedImageUploadClicked: () => void;
    manualEntryClicked: (payload: IManualJobEntryInput) => void;
}

export default function FabButtons(props: FabButtonProps) {

    const scannerId = useSelector((store: IStore) => store.user.userProfile.response?.settings.scanner_id);

    const lightBoxEnabled = useSelector((store: IStore) => store.user.userProfile.response?.settings.lightbox_enabled) || false;
    const manualEntryEnabled = useSelector((store: IStore) => store.user.userProfile.response?.settings.manual_entry_enabled) || false;

    const [isManualEntryModalVisible, setIsManualEntryModalVisible] = React.useState(false);
    const [numberOfKernels, setNumberOfKernels] = React.useState("0");

    const [isOpen, setIsOpen] = React.useState(false);

    const [fabConfig, setFabConfig] = React.useState({
        captureImage: {
            enabled: false,
            bottom: 253
        },
        scanImage: {
            enabled: false,
            bottom: 135
        },
        manualEntry: {
            enabled: false,
            bottom: 194
        },
        galleryImage: {
            enabled: true,
            bottom: 76
        },
    });

    useEffect(() => {
        // by default upload image from gallery is enabled
        const total = 4;
        let countEnabled = 1;
        let bottom = 76;

        const newFabConfig = { ...fabConfig };

        if (manualEntryEnabled) {
            newFabConfig.manualEntry.enabled = true;
            newFabConfig.manualEntry.bottom = bottom + 59;
            bottom = bottom + 59;
        }

        if (isAppOrWindows()) {
            newFabConfig.scanImage.enabled = true;
            newFabConfig.scanImage.bottom = bottom + 59;
            bottom = bottom + 59;
        }

        if (lightBoxEnabled) {
            newFabConfig.captureImage.enabled = true;
            newFabConfig.captureImage.bottom = bottom + 59;
            bottom = bottom + 59;
        }

        setFabConfig(newFabConfig);

    }, [lightBoxEnabled, manualEntryEnabled]);

    return (
        <>
            <Modal
                size={"lg"}
                isOpen={isManualEntryModalVisible}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>Sample Details</Modal.Header>
                    <Modal.Body>
                        <VStack p="2" space={4}>
                            <Input
                                key={"numberOfKernels"}
                                style={inputStyle.measurementInput}
                                placeholder="Number of Kernels"
                                onChangeText={(text) => {
                                    setNumberOfKernels(text)
                                }}
                                value={numberOfKernels} />
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                                setIsManualEntryModalVisible(false)
                            }}>
                                Cancel
                            </Button>
                            <Button
                                onPress={async () => {
                                    setIsManualEntryModalVisible(false)
                                    props.manualEntryClicked({
                                        grain_count: numberOfKernels
                                    });
                                }
                                }>Create Manual Entry</Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <VStack>
                {
                    isOpen && <>
                        {
                            fabConfig.captureImage.enabled &&
                            <Fab
                                renderInPortal={false}
                                shadow={2}
                                size="sm"
                                onPress={() => {
                                    setIsOpen(!isOpen);
                                    props.captureImageClicked();
                                }}
                                bottom={fabConfig.captureImage.bottom}
                                right={5}
                                label={getText('takePhoto')}
                                disabled={props.isDisabled}
                                icon={<MaterialIconByName name="camera" />} />
                        }
                        {
                            fabConfig.manualEntry.enabled &&
                            <Fab
                                renderInPortal={false}
                                shadow={2}
                                size="sm"
                                onPress={() => {
                                    setIsOpen(!isOpen);
                                    setIsManualEntryModalVisible(true);
                                }}
                                bottom={fabConfig.manualEntry.bottom}
                                right={5}
                                label={getText('manualEntry')}
                                disabled={props.isDisabled}
                                icon={<MaterialIconByName name="pencil" />} />
                        }
                        {
                            fabConfig.scanImage.enabled &&
                            <Fab
                                renderInPortal={false}
                                shadow={2}
                                size="sm"
                                onPress={() => {
                                    setIsOpen(!isOpen);
                                    props.scanImageClicked();
                                }}
                                bottom={fabConfig.scanImage.bottom}
                                right={5}
                                label={getText('scanImage')}
                                disabled={props.isDisabled}
                                icon={<MaterialIconByName name="scanner" />} />
                        }

                        {
                            fabConfig.galleryImage.enabled &&
                            <Fab
                                renderInPortal={false}
                                shadow={2}
                                size="sm"
                                onPress={() => {
                                    setIsOpen(!isOpen);

                                    if (scannerId === ScannerId.DOUBLE_SIDED) {
                                        props.doubleSidedImageUploadClicked();
                                    } else {
                                        props.imageUploadClicked();
                                    }
                                }}
                                bottom={fabConfig.galleryImage.bottom}
                                right={5}
                                label={scannerId === ScannerId.DOUBLE_SIDED
                                    ? getText('chooseImagesDs')
                                    : getText('chooseImagesDs')}
                                disabled={props.isDisabled}
                                icon={<MaterialIconByName name="image-area" />} />
                        }
                    </>
                }

                <Fab
                    renderInPortal={false}
                    shadow={2}
                    size="lg"
                    onPress={() => {
                        setIsOpen(!isOpen);
                    }}
                    icon={<MaterialIconByName name={isOpen ? "close" : "plus"} />} />
            </VStack>
        </>
    );
}

