import * as UploadAction from 'src/redux/actions/dataset';
import * as JobActions from 'src/redux/actions/jobAction';
import { LabelingStatus } from 'src/models/LabelingStatus';
import { Alert } from 'react-native';
import { onSubmitLabelingRequest, IImageWithLabel, ChangedLabels } from 'src/models/UploadImages';
import { NavigationProp } from '@react-navigation/native';

// Type Definitions for Dispatch, State, and Navigation
import { Dispatch } from 'redux';
import { AnyAction } from 'redux';

type NavigationType = NavigationProp<any>;
export const fetchData = async (
  dispatch: Dispatch<AnyAction>,
  isJob: boolean,
  jobId: string | null,
  datasetId: string | null,
  isExistingDataset: boolean,
  setIsDataFetched: (fetched: boolean) => void
): Promise<void> => {
  if (!datasetId && !jobId) return;

  try {
    if (isJob) {
      if (!jobId) throw new Error('jobId is undefined');
      if (isExistingDataset) {
        await dispatch(UploadAction.getJobDetails.request({ datasetId: jobId }));
      } else {
        dispatch(JobActions.jobStatus.request(jobId));
      }
    } else {
      if (!datasetId) throw new Error('datasetId is undefined');
      console.log('Fetching dataset details', datasetId);
      await dispatch(UploadAction.fetchDatasetDetails.request({ datasetId }));
    }
  } catch (error: any) {
    console.error('Error in fetchData:', error);
    Alert.alert('Error', 'Failed to fetch data. Please try again.');
  } finally {
    setIsDataFetched(true);
  }
};

/**
 * Save the current state as a draft, updating labels if necessary.
 */
export const handleSaveAsDraft = async (
  dispatch: Dispatch<AnyAction>,
  isJob: boolean,
  jobId: string | null,
  datasetId: string | null,
  grainId: string,
  changedLabels: ChangedLabels,
  images: IImageWithLabel[],
  navigation: NavigationType
): Promise<void> => {
  try {
    const updatedImages: IImageWithLabel[] = images.map(img => ({
      ...img,
      label: changedLabels[img.imagePath] || img.label,
    }));
    console.log('Updated images:', updatedImages);

    const saveAsDraftRequest: onSubmitLabelingRequest = {
      datasetId: isJob ? jobId! : datasetId!,
      changedLabels,
      status: LabelingStatus.IN_PROGRESS,
      grainId,
    };

    if (isJob) {
      await dispatch(UploadAction.setDatasetId(jobId!));
      await dispatch(UploadAction.setImages(updatedImages));
      await dispatch(UploadAction.saveAsDraft.request(saveAsDraftRequest));
    } else {
      await dispatch(UploadAction.saveAsDraft.request(saveAsDraftRequest));
    }

    navigation.navigate('DatasetListingPage', { refresh: true });
  } catch (error: any) {
    console.error('Error in handleSaveAsDraft:', error);
    Alert.alert('Error', 'Failed to save as draft. Please try again.');
  }
};

/**
 * Complete labeling for all images, ensuring all labels are set.
 */
export const handleCompleteLabeling = (
  dispatch: Dispatch<AnyAction>,
  isJob: boolean,
  jobId: string | null,
  datasetId: string | null,
  grainId: string,
  changedLabels: ChangedLabels,
  images: IImageWithLabel[],
  navigation: NavigationType
): void => {
  // Ensure all images are labeled
  if (images.every(img => img.label || changedLabels[img.imagePath])) {
    const updatedImages: IImageWithLabel[] = images.map(img => ({
      ...img,
      label: changedLabels[img.imagePath] || img.label,
    }));

    const completeLabelingRequest: onSubmitLabelingRequest = {
      datasetId: isJob ? jobId! : datasetId!,
      changedLabels,
      status: LabelingStatus.COMPLETED,
      grainId,
    };

    if (isJob) {
      dispatch(
        UploadAction.upload.request({
          files: updatedImages.map(img => img.imagePath),
          datasetId: jobId!,
          grainId: grainId,
        })
      );
      dispatch(UploadAction.completeLabeling.request(completeLabelingRequest));
    } else {
      dispatch(UploadAction.completeLabeling.request(completeLabelingRequest));
    }

    navigation.navigate('DatasetListingPage', { refresh: true });
  } else {
    Alert.alert('Error', 'Please label all images before completing.');
  }
};
