import React from 'react';
import { HStack, Text, Button } from 'native-base';

interface DatasetListItemProps {
  item: any;
  handleDatasetSelect: (datasetID: string) => void;
  handleUploadImages: (datasetID: string) => void;
  getGrainName: (grainId: string) => string;
}

const DatasetListItem: React.FC<DatasetListItemProps> = ({ 
  item, 
  handleDatasetSelect, 
  handleUploadImages, 
  getGrainName 
}) => {
  if (!item) {
    return null; 
  }

  return (
    <HStack space={2} justifyContent="space-between" alignItems="center" my={2}>
      <Text flex={2}>{item.datasetID}</Text>
      <Text flex={1}>{getGrainName(item.grainId)}</Text>
      <Text flex={1}>{item.username}</Text>
      <Text flex={1} textAlign="center">{item.status}</Text>
      <Button flex={1} onPress={() => handleDatasetSelect(item.datasetID)}>
        Label Dataset
      </Button>
      <Button flex={1} onPress={() => handleUploadImages(item.datasetID)}>
        Upload Images
      </Button>
    </HStack>
  );
};

export default DatasetListItem;