
import React, { useState } from 'react';
import { Box, Select, useToast } from 'native-base';
import { useDispatch } from 'react-redux';
import { updateLocale } from 'src/redux/actions/appAction';
import { useSelector } from 'react-redux';
import { CheckIcon } from 'native-base';
const languageOptions = {
  en: 'English',
  hi: 'Hindi',
};

const LanguageDropdown = () => {
  const locale = useSelector((state: any) => state.app.locale);
  const dispatch = useDispatch();
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const toast = useToast();

  const handleChangeLocale = (newLocale: keyof typeof languageOptions) => {
    setSelectedLocale(newLocale);
    dispatch(updateLocale(newLocale));
    toast.show({ title: `Language set to ${languageOptions[newLocale]}` });
  };

  return (
    <Box>
      <Select
        selectedValue={selectedLocale}
        minWidth={200}
        onValueChange={(value) => handleChangeLocale(value as keyof typeof languageOptions)}
        placeholder="Select Language"
        accessibilityLabel="Select Language"
        _selectedItem={{
          bg: 'teal.600',
          endIcon: <CheckIcon size="5" />,
        }}
      >
        {Object.keys(languageOptions).map((key) => (
          <Select.Item key={key}
            label={languageOptions[key as keyof typeof languageOptions]}
            value={key} />
        ))}
      </Select>
    </Box>
  );
};

export default LanguageDropdown;
