import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useSelector } from 'react-redux';
import ResultsPage from '../../pages/ResultsPage';
import ImagePage from '../../pages/ImagePage';
import InfoPage from '../../pages/InfoPage';
import IndiGrainResultsPage from '../../pages/IndiGrainResultsPage';
import ReadingsPage from '../../pages/ReadingsPage';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { getText } from 'src/utils/i18n';

const Tab = createMaterialTopTabNavigator();
export default function HomeTabs({ navigation }) {

    return (
        <Tab.Navigator
            screenOptions={() => ({
                tabBarActiveTintColor: 'black',
                tabBarInactiveTintColor: 'gray',
                tabBarLabelStyle: { fontSize: 8 },
            })}>
            <Tab.Screen name="Info"
                component={InfoPage}
                options={{
                    tabBarLabel: getText('info') ,
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="information" color={color} size={24} />
                    ),
                }} />
            <Tab.Screen
                name="Image"
                component={ImagePage} options={{
                    tabBarLabel: getText('image'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="image" color={color} size={24} />
                    ),
                }} />
            <Tab.Screen
                name="Indi Grains"
                component={IndiGrainResultsPage} options={{
                    tabBarLabel: getText('readings'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="table-large" color={color} size={24} />
                    ),
                }} />
            <Tab.Screen
                name="Analysis"
                component={ResultsPage} options={{
                    tabBarLabel: getText('analysis'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="view-quilt-outline" color={color} size={24} />
                    ),
                }} />
            <Tab.Screen
                name="History"
                component={ReadingsPage} options={{
                    tabBarLabel: getText('history'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="clipboard-text-clock" color={color} size={24} />
                    ),
                }} />
        </Tab.Navigator>
    );
}