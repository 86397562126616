import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import SurveyorListComponent from "src/components/list/SurveyorListComponent";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import OrgJobHistoryList from '../list/OrgJobHistoryList';
import OrgSummaryComponent from '../OrgSummaryComponent';
import { IStore } from 'src/redux/reducers';
import { getText } from '../../utils/i18n';
import { useSelector } from 'react-redux';
const Tab = createMaterialTopTabNavigator();

export default function OrgDashboardTabs({ navigation }) {
    const showJobHistory = useSelector((store: IStore) => store.user.userProfile.response?.settings?.show_job_history);
    return (
        <Tab.Navigator
            screenOptions={() => ({
                tabBarActiveTintColor: 'black',
                tabBarInactiveTintColor: 'gray',
                tabBarLabelStyle: { fontSize: 8 },
            })}>
            <Tab.Screen
                name="Summary"
                component={OrgSummaryComponent}
                options={{
                tabBarLabel: getText('summary'),
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="information" color={color} size={24} />
                ),
                }} />
            <Tab.Screen
                name="Surveyors"
                component={SurveyorListComponent} options={{
                    tabBarLabel: getText('surveyors'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="account-supervisor-outline" color={color} size={24} />
                    ),
                }} />
            {showJobHistory && (
                <Tab.Screen
                    name="History"
                    component={OrgJobHistoryList} options={{
                        tabBarLabel: 'ALL READINGS',
                        tabBarIcon: ({ color }) => (
                            <MaterialCommunityIcons name="clipboard-text-clock" color={color} size={24} />
                        ),
                    }} />
            )}
        </Tab.Navigator>
    );
}
