import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as AppAction from '../../../redux/actions/appAction';
import { View } from 'react-native';
import { Button, HStack, Modal } from 'native-base';
import MaterialIconByName from 'src/components/MaterialIconByName';
import { IStore } from 'src/redux/reducers';
import { ISearchQuery, IDateRange } from 'src/models';
import JobFilters from 'src/components/JobFilters';
import { getText } from 'src/utils/i18n';
export default function FilterModal() {

    const [filterModalVisible, setFilterModalVisible] = React.useState(false);
    const [numberOfFiltersApplied, setNumberOfFiltersApplied] = React.useState(0);
    const currentStack = useSelector((store: IStore) => store.app.currentStack);

    const showFilterModal = () => setFilterModalVisible(true);
    const hideFilterModal = () => setFilterModalVisible(false);

    const dispatch = useDispatch();

    const resetFilters = async () => {
        dispatch(AppAction.resetFilters());
    }

    const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
    const searchQuery: ISearchQuery | undefined = useSelector((store: IStore) => store.app.searchQuery);
    const dateRange: IDateRange | undefined = useSelector((store: IStore) => store.app.dateRange);
    const stateFilter = useSelector((store: IStore) => store.app.stateFilter);
    const districtFilter = useSelector((store: IStore) => store.app.districtFilter);

    const centerIdFilter = useSelector((store: IStore) => store.app.centerIdFilter) || "all";



    const getNumberOfFiltersApplied = () => {
        let count = 0;
        if (grainIdFilter !== undefined
            && grainIdFilter !== ""
            && grainIdFilter !== "0"
            && grainIdFilter !== "all") {
            count++;
        }
        if (searchQuery !== undefined && searchQuery?.query !== undefined && searchQuery?.query !== "") {
            count++;
        }
        if (dateRange !== undefined && dateRange?.from !== undefined && dateRange?.to !== undefined) {
            count++;
        }

        if (centerIdFilter !== ""
            && centerIdFilter !== undefined
            && centerIdFilter !== "all") {
            count++;
        }

        if (stateFilter !== ""
            && stateFilter !== undefined
            && stateFilter !== "all") {
            count++;
        }

        if (districtFilter !== ""
            && districtFilter !== undefined
            && districtFilter !== "all") {
            count++;
        }

        return count;
    }

    React.useEffect(() => {
        setNumberOfFiltersApplied(getNumberOfFiltersApplied());
    }, [grainIdFilter, searchQuery, dateRange, centerIdFilter, stateFilter, districtFilter]);

    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    return (
        <View>
            <Modal
                size={"xl"}
                isOpen={filterModalVisible}
                onClose={hideFilterModal}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>{getText('filters')}</Modal.Header>
                    <Modal.Body>
                        <JobFilters />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant="ghost" colorScheme="blueGray" onPress={hideFilterModal}>
                                {getText('cancel')}
                            </Button>
                            <Button
                                onPress={hideFilterModal}>{getText('saveFilters')}</Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <HStack style={{
                marginTop: 8,
                marginBottom: 16
            }} space={2} justifyContent={"center"}>
                <Button
                    startIcon={<MaterialIconByName name="filter" />}
                    onPress={showFilterModal}>
                    {getText('filters')}
                </Button>
                <Button startIcon={<MaterialIconByName name="close" />}
                    onPress={resetFilters}>
                    {numberOfFiltersApplied > 0
                        ? `Reset (${numberOfFiltersApplied} ${numberOfFiltersApplied === 1 ? 'filter'
                            : 'filters'})` : "Reset"}
                </Button>
            </HStack>

        </View>
    );
};