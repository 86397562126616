// NoImagesAvailable.js
import React from 'react';
import { Box, Button, Text } from 'native-base';

const NoImagesAvailable = ({ navigation, isJob = false }) => {
    return (
        <Box flex={1} justifyContent="center" alignItems="center">
            <Text>No images available. Please {isJob ? 'select a different dataset' : 'upload images first or select an existing Dataset'}.</Text>
            {!isJob && (
                <Button mt={4} onPress={() => navigation.navigate('ImageUploadPage')}>
                    Upload Images
                </Button>
            )}
            <Button mt={4} onPress={() => navigation.navigate(isJob ? 'JobListingPage' : 'DatasetListingPage')}>
                {isJob ? 'Go to Job Listing' : 'Select Dataset'}
            </Button>
        </Box>
    );
};

export default NoImagesAvailable;