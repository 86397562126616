import React, { useState } from 'react';
import DisplayCsvTableComponent from '../DisplayCsvTableComponent';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { InfoField } from 'src/models';
import { Box } from 'native-base';
import { S3_BUCKET_URL } from 'src/constants';
import { getText } from 'src/utils/i18n';
import MeasurementInfo from '../MeasurementInfo';

interface InfoFieldsDisplayProps {
    info?: { [key: string]: string };
}

export default function InfoFieldsDisplay(props: InfoFieldsDisplayProps) {
    const { info } = props;
    const [tableData, setTableData] = useState<any[]>([]);

    const infoFields = useSelector((store: IStore) => store.user.userProfile.response?.settings.info_fields);

    React.useEffect(() => {
        if (info && infoFields) {
            for (let i = 0; i < infoFields.length; i++) {
                const field: InfoField = infoFields[i];

                if (field.type === "imageUpload") {
                    const s3Path = info[field.fieldName];

                    if (s3Path) {
                        const s3Url = `${S3_BUCKET_URL()}/${s3Path}`;
                        const imageLink = `<a target='_blank' href=${s3Url} >Download app</a>`
                        info[field.fieldName] = imageLink;
                    }
                }

                tableData.push([field.displayName, info[field.fieldName] ?? ""]);
            }

            setTableData(tableData);
        }
    }, [info, infoFields]);

    return (
        <Box style={{
            paddingBottom: 18,
        }}>
            <DisplayCsvTableComponent
                columns={[0, 1]}
                title={getText('measurementInfo')}
                tableHead={["Field", "Value"]}
                showHead={false}
                tableData={tableData} />
        </Box>
    );
};