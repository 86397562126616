import React from 'react';
import { HStack, IconButton, Text } from 'native-base';
import MaterialIconByName from 'src/components/MaterialIconByName';

const Pagination = ({ page, totalPages, onPageChange }) => {
    return (
        <HStack space={2} style={{ alignItems: 'center', justifyContent: 'flex-end', marginTop: 16 }}>
            <Text>
                Page {page + 1} of {totalPages}
            </Text>
            <IconButton
                onPress={() => onPageChange(Math.max(0, page - 1))}
                borderRadius="full"
                icon={<MaterialIconByName name="chevron-left" />}
                disabled={page === 0}
            />
            <IconButton
                onPress={() => onPageChange(Math.min(totalPages - 1, page + 1))}
                borderRadius="full"
                icon={<MaterialIconByName name="chevron-right" />}
                disabled={page === totalPages - 1}
            />
        </HStack>
    );
};

export default Pagination;