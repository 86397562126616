import React, { useState } from 'react';
import * as AppActions from "../../redux/actions/appAction";
import { View } from 'react-native';
import { useDispatch, useSelector } from "react-redux";
import { Select, CheckIcon, Input, Pressable, VStack, Text, IconButton } from "native-base";
import { containerStyle } from 'src/styles/containerStyle';
import { textStyle } from 'src/styles/textStyle';
import MaterialIconByName from '../MaterialIconByName';
import {getText} from 'src/utils/i18n'

const allSearchOptions = [
  {
    label: "Job ID",
    value: "jobId"
  }, {
    label: "Farmer Code",
    value: "farmerCode"
  }
]

interface ISearchComponentProps {
  enableSearchBy?: string[];
}

export default function CenterDropdown(props: ISearchComponentProps) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const inititalSearchType = props && props.enableSearchBy && props.enableSearchBy[0] || "jobId";
  const [searchType, setSearchType] = useState(inititalSearchType);
  const [searchTerm, setSearchTerm] = useState("");

  const onSelectedValueChanged = (val: string) => {
    setSearchType(val)
    dispatch(AppActions.setSearchQuery({
      type: val,
      query: searchTerm
    }));
  }

  const onSearchQueryChanged = (val: string) => {
    setSearchTerm(val)
    dispatch(AppActions.setSearchQuery({
      type: searchType,
      query: val
    }));
  }

  return (
    <View style={containerStyle.componentContainer}>
      <VStack space={2}>
        <Text style={textStyle.subSectionText}>{getText('searchBy')}</Text>
        <Select style={{
          width: "95%"
        }} selectedValue={searchType}
          accessibilityLabel="Search by"
          placeholder="Search by" _selectedItem={{
            bg: "teal.600",
            endIcon: <CheckIcon size="5" />
          }} mt={1} onValueChange={itemValue => onSelectedValueChanged(itemValue)}>
          {
            allSearchOptions
              .filter(option => {
                if (props.enableSearchBy === undefined || props.enableSearchBy.length === 0) {
                  return true
                }
                return props.enableSearchBy.includes(option.value)
              })
              .map((option, index) => {
                return (
                  <Select.Item key={index} label={option.label} value={option.value} />
                )
              })
          }
        </Select>
        <Input
          InputRightElement={
            <IconButton
              onPress={() => {
                onSearchQueryChanged("")
              }}
              icon={<MaterialIconByName name="close" />} />
          }
          value={searchTerm}
          onChangeText={onSearchQueryChanged}
          placeholder="Search query" />
      </VStack>
    </View>
  );
};