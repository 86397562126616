import React, { useCallback, useState, useRef, useEffect } from 'react'; 
import { ScrollView, FlatList, Text, Spinner, VStack, HStack, Divider, Button, Center, Input } from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import * as UploadAction from 'src/redux/actions/dataset';
import * as JobActions from 'src/redux/actions/jobAction';
import * as GrainTypeAction from 'src/redux/actions/grainTypeAction';
import { useFocusEffect } from '@react-navigation/native';
import { IJobs } from 'src/models/Job';
import { IStore } from 'src/redux/reducers';
import GrainTypeDropdown from 'src/components/dropdown/GrainTypeDropdown';
import { getGrainName } from 'src/utils/utils';
import DatasetListItem from 'src/components/listItem/DatasetListItem';
import { Dataset } from 'src/models/UploadImages';

import { NavigationProp, RouteProp } from '@react-navigation/native';

interface DatasetListingPageProps {
  navigation: NavigationProp<any>;
  route: RouteProp<any>;
}

const DatasetListingPage = ({ navigation, route }: DatasetListingPageProps) => {
  const dispatch = useDispatch();
  
  const { datasets = [], loading } = useSelector((state: IStore) => state.dataset);
  const orgJobs: IJobs | null | undefined = useSelector((store: IStore) => store.job.orgJobs.jobs);
  const orgId: string = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId) ?? '';
  const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);

  const [searchTerm, setSearchTerm] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  const firstRender = useRef(true);

  
  const [filteredDatasets, setFilteredDatasets] = useState<Dataset[]>([]);
  const filterGrainId = useSelector((store: IStore) => store.app.grainIdFilter) || "all";

  useFocusEffect(
    useCallback(() => {
      if (firstRender.current || route.params?.refresh) {
        dispatch(UploadAction.fetchDatasetInfo.request());
        if (!orgJobs) {
          dispatch(JobActions.orgJobs.request({ orgId }));
        }
        setRefreshKey((prev) => prev + 1);
        firstRender.current = false;
      }

      return () => {
        if (route.params?.refresh) {
          navigation.setParams({ refresh: undefined });
        }
      };
    }, [dispatch, orgJobs, route.params?.refresh, orgId])
  );

  const handleDatasetSelect = useCallback(async (datasetId: string) => {
      await dispatch(UploadAction.clearDataset());
      setTimeout(() => {
        const selectedDataset = datasets.find((dataset) => dataset.datasetID === datasetId);
    
        if (selectedDataset) {
          dispatch(GrainTypeAction.setGrainId(selectedDataset.grainId));
    
          if (!datasetId.startsWith('dataset_')) {
            const selectedJobStatus = orgJobs?.Items.find((job) => job.jobId === datasetId);
            if (selectedJobStatus) {
              dispatch(JobActions.jobStatus.success(selectedJobStatus));
              dispatch(JobActions.setJobId(datasetId));
              dispatch(JobActions.pollJobStatus.request(datasetId));
            }
            dispatch(UploadAction.setIsJob(true));
            dispatch(UploadAction.setId(datasetId));
            navigation.navigate('CombinedLabelingPage');
          } else {
            dispatch(UploadAction.setDatasetId(datasetId));
            dispatch(UploadAction.setIsJob(false));
            dispatch(UploadAction.setId(datasetId));
            navigation.navigate('CombinedLabelingPage');
          }
        }
      }, 2000);
    }, [dispatch, navigation, orgJobs, datasets]);
   

  const handleUploadImages = useCallback((datasetId: string) => {
    dispatch(UploadAction.setIsExistingDataset(true));
    dispatch(UploadAction.setDatasetId(datasetId));
    navigation.navigate('ImageUploadPage');
  }, [dispatch, navigation]);

  const filterBySearchTerm = (dataset: Dataset) => {
    return (
      dataset.datasetID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      dataset.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      getGrainName(grainTypes, dataset.grainId).toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filterByGrainId = (dataset: Dataset) => {
    return filterGrainId === 'all' || dataset.grainId === filterGrainId;
  };

  const sortByCreatedAt = (a: Dataset, b: Dataset) => {
    return b.createdAt - a.createdAt;
  };

  useEffect(() => {
    const filtered = datasets
      .filter(dataset => filterBySearchTerm(dataset) && filterByGrainId(dataset))
      .sort(sortByCreatedAt);

    setFilteredDatasets(filtered);
  }, [datasets, searchTerm, filterGrainId]);

  const renderItem = useCallback(({ item }: { item: Dataset }) => (
    <DatasetListItem
      item={item}
      handleDatasetSelect={handleDatasetSelect}
      handleUploadImages={handleUploadImages}
      getGrainName={(grainId) => getGrainName(grainTypes, grainId)}
    />
  ), [handleDatasetSelect, handleUploadImages, grainTypes]);

  if (loading) {
    return (
      <Center flex={1} px="3">
        <Spinner accessibilityLabel="Loading datasets" />
      </Center>
    );
  }

  return (
    <ScrollView>
      <VStack space={4} p={5}>
        <Text fontSize="xl" fontWeight="bold">Available Datasets</Text>
        <HStack space={2}>
          <Input
            flex={1}
            placeholder="Search by Dataset ID, Username, or Grain Name"
            value={searchTerm}
            onChangeText={setSearchTerm}
          />
          <GrainTypeDropdown all />
        </HStack>
        <Divider />
        <FlatList
          data={filteredDatasets}
          renderItem={renderItem}
          keyExtractor={(item) => item.datasetID}
          extraData={refreshKey}
        />
      </VStack>
    </ScrollView>
  );
};

export default DatasetListingPage;
