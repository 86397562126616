import React, { useEffect } from 'react';
import { IStore } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as JobActions from '../../../redux/actions/jobAction';
import { View, FlatList, RefreshControl } from 'react-native';
import { IJob, IJobs, ISearchQuery, IOrgJobListItem, IDateRange } from '../../../models';
import OrgJobHistoryListItem from '../../listItem/OrgJobHistoryItem';
import FilterModal from '../../modal/FilterModal';
import { Button } from 'native-base';
import { isDisplayableJob } from '../../../utils/jobUtils';
import { listStyle } from 'src/styles/listStyle';
import { containerStyle } from 'src/styles/containerStyle';
import { downloadScannedImageFromId } from 'src/services/download';

export default function OrgJobHistoryList({ navigation }) {

  const currentStack = useSelector((store: IStore) => store.app.currentStack);

  const [refreshing, setRefreshing] = React.useState(false);
  
  const orgJobs: IJobs | null | undefined = useSelector((store: IStore) => store.job.orgJobs.jobs);
  
  const centers = useSelector((store: IStore) => store.org.centers.centers);
  const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);
  
  const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const centerIdFilter = useSelector((store: IStore) => store.app.centerIdFilter) || "all";
  const searchQuery: ISearchQuery | undefined = useSelector((store: IStore) => store.app.searchQuery);
  const stateFilter = useSelector((store: IStore) => store.app.stateFilter) || "all";
  const districtFilter = useSelector((store: IStore) => store.app.districtFilter) || "all";
  const dateRange: IDateRange | undefined = useSelector((store: IStore) => store.app.dateRange);
  const orgId: string | undefined = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);

  const dispatch = useDispatch();

  const [listData, setListData] = React.useState<IOrgJobListItem[]>([]);

  useEffect(() => {
    fetchOrgJobs();
  }, [orgId]);

  useEffect(() => {
    updateOrgList();
  }, [grainIdFilter, searchQuery, dateRange, centerIdFilter, orgJobs, centers, grainTypes, stateFilter, districtFilter]);

  const fetchOrgJobs = async () => {
    if (orgId !== undefined) {
      dispatch(JobActions.orgJobs.request({
        'orgId': orgId
      }))
    }
  }

  const fetchMoreOrgJobs = async () => {
    fetchOrgJobs()
  }

  const updateOrgList = () => {
    let listItems: IOrgJobListItem[] = []
    orgJobs?.Items
      .filter((item) => isDisplayableJob(item.jobStatus))
      .filter((item: IJob) => {
        let centerId = undefined
        if (item.info !== undefined && item.info['centerId'] !== undefined) {
          centerId = item.info['centerId']
        }
        return filterByCenterId(centerId)
      })
      .filter((item) => filterByGrainId(item.grainId))
      .filter((item: IJob) => filterBySearchQuery(item))
      .filter((item: IJob) => filterByDateRange(item))
      .filter((item: IJob) => filterByState(item))
      .filter((item: IJob) => filterByDistrict(item))
      .forEach((item) => {
        let centerName = ""
        if (item.info !== undefined && item.info['centerId'] !== undefined) {
          centerName = getCenterName(item.info['centerId'])
        }

        listItems.push({
          "jobId": item.jobId,
          "jobStatus": item.jobStatus,
          "grainId": item.grainId,
          "username": item.username,
          "centerName": centerName,
          "createdAt": item.createdAt
        })
      })
    setListData(listItems)
  }

  const getCenterName = (centerId: string | undefined) => {
    for (var i = 0; i < centers?.length; i++) {
      if (centerId === centers[i].centerId) {
        return centers[i].name
      }
    }
    return ""
  }

  const filterByGrainId = (id: any) => {
    if (grainIdFilter === undefined
      || grainIdFilter === ""
      || grainIdFilter === "0"
      || grainIdFilter === "all") {
      return true
    }
    return id === grainIdFilter
  }

  const filterBySearchQuery = (item: IJob) => {
    if (searchQuery === undefined || searchQuery?.query === undefined || searchQuery?.query === "") {
      return true
    }

    if (searchQuery.type === 'jobId') {
      return item.jobId.includes(searchQuery.query)
    }
    return false
  }

  const filterByDateRange = (item: IJob) => {
    if (dateRange === undefined || dateRange?.from === undefined || dateRange?.to === undefined) {
      return true
    }

    return item.createdAt >= dateRange.from && item.createdAt <= dateRange.to;
  }

  const filterByCenterId = (id: any) => {
    if (centerIdFilter === ""
      || centerIdFilter === undefined
      || centerIdFilter === "all") {
      return true
    }
    return centerIdFilter === id
  }
  const filterByState = (item: IJob) => {
    if (stateFilter === "all") {
      return true
    }

    const centerId = item.info?.centerId;
    if (centerId) {
      const center = centers.find(c => c.centerId === centerId);
      return center ? center.state === stateFilter : false;
    }

    return false;
  }

  const filterByDistrict = (item: IJob) => {
    if (districtFilter === "all") {
      return true
    }

    const centerId = item.info?.centerId;
    if (centerId) {
      const center = centers.find(c => c.centerId === centerId);
      return center ? center.addressDistrict === districtFilter : false;
    }

    return false;
  }

  const renderItem = ({ item }) => {
    return <OrgJobHistoryListItem
      item={item}
      downloadImageCallback={async () => {
        await downloadScannedImageFromId(item.jobId)
      }}
      itemCallback={() => {
        const selectedJobStatus = orgJobs?.Items.find((job) => job.jobId === item.jobId);
        if (selectedJobStatus !== undefined) {
          dispatch(JobActions.jobStatus.success(selectedJobStatus));
          dispatch(JobActions.setJobId(item.jobId));
          dispatch(JobActions.pollJobStatus.request(item.jobId));
        }
        navigation.navigate('ResultsPage');
      }}
      retryJobCallback={function (item: IOrgJobListItem): void {
        dispatch(JobActions.queueJob.request({
          'jobId': item.jobId
        }));
      }} />
  };

  const renderFooter = () => {
    return (
      <View style={listStyle.footer}>
        <Button onPress={() => {
          fetchMoreOrgJobs()
        }} isLoading={refreshing} variant="subtle">Load more</Button>
      </View>
    );
  };

  return (
    <View style={containerStyle.listContainer}>
      {
        currentStack !== 'orgSuperAdmin'
        && <FilterModal />
      }
      <FlatList
        data={listData}
        renderItem={renderItem}
        keyExtractor={item => item.jobId}
        ListFooterComponent={renderFooter}
        onEndReached={fetchMoreOrgJobs}
        onEndReachedThreshold={0.3}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={() => {
            fetchOrgJobs();
          }} />
        }
      />
    </View>
  );
};