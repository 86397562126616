import React, { useCallback, memo } from 'react';
import { Image } from 'react-native';
import { Box, HStack, VStack, Text, Select, CheckIcon, FlatList } from 'native-base';
import { imageStyle } from 'src/styles/imageStyle';
import { IImageItem, IRejectionMapping } from 'src/models/UploadImages';
interface ImageItemProps {
  item: IImageItem;
  currentLabel: string;
  rejectionMapping:IRejectionMapping;
  onLabelChange: (imagePath: string, label: string) => void;
}

const ImageItem: React.FC<ImageItemProps> = memo(
  ({ item, currentLabel, rejectionMapping, onLabelChange }) => {
    const imagePathDisplay = item.imagePath ? item.imagePath.split('/').pop() : 'Unknown';

    return (
      <Box borderWidth="1" borderColor="coolGray.200" borderRadius="md" p="5" mb="5" bg="white">
        <HStack space={4} alignItems="center">
          <Box style={imageStyle.imageContainer}>
            <Image
              source={{ uri: item.imageUrl }}
              style={imageStyle.image}
              onError={(error) => {
                console.error('Image load error:', error);
              }}
            />
          </Box>
          <VStack flex={1}>
            <Text fontSize="md" bold>
              Image Path: {imagePathDisplay}
            </Text>
            <Select
              selectedValue={currentLabel}
              minWidth="200"
              accessibilityLabel="Select Label"
              placeholder="Select Label"
              _selectedItem={{
                bg: 'cyan.600',
                endIcon: <CheckIcon size="5" />,
              }}
              mt="1"
              onValueChange={(value) => onLabelChange(item.imagePath, value)}
            >
              {Object.entries(rejectionMapping).map(([key, value]) => (
                <Select.Item key={key} label={value} value={value} />
              ))}
            </Select>
          </VStack>
        </HStack>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    // Prevent re-render unless currentLabel or rejectionMapping changes
    return (
      prevProps.currentLabel === nextProps.currentLabel &&
      prevProps.rejectionMapping === nextProps.rejectionMapping
    );
  }
);

interface ImageTableProps {
  images: Array<IImageItem>;
  changedLabels: IRejectionMapping;
  rejectionMapping: IRejectionMapping;
  onLabelChange: (imagePath: string, label: string) => void;
}

const ImageTable: React.FC<ImageTableProps> = memo(
  ({ images, changedLabels, rejectionMapping, onLabelChange }) => {
    console.log('ImageTable:', images);

    const renderImageItem = useCallback(
      ({ item }: { item: IImageItem }) => {
        const currentLabel = changedLabels[item.imagePath] || item.label;
        return (
          <ImageItem
            item={item}
            currentLabel={currentLabel}
            rejectionMapping={rejectionMapping}
            onLabelChange={onLabelChange}
          />
        );
      },
      [changedLabels, rejectionMapping, onLabelChange]
    );

    return (
      <FlatList
        data={images}
        renderItem={renderImageItem}
        keyExtractor={(item) => item.imagePath}
        ListEmptyComponent={() => <Text>No images to display.</Text>}
        extraData={changedLabels}
      />
    );
  },
  (prevProps, nextProps) => {
    // Prevent re-render unless images, changedLabels, or rejectionMapping change
    return (
      prevProps.images === nextProps.images &&
      prevProps.changedLabels === nextProps.changedLabels &&
      prevProps.rejectionMapping === nextProps.rejectionMapping
    );
  }
);

export default ImageTable;